var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data Pegawai ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NIP","label-for":"nip"}},[_c('validation-provider',{attrs:{"name":"nip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nip","type":"number","state":errors.length > 0 ? false : null,"placeholder":"NIP"},model:{value:(_vm.dataParent.nip),callback:function ($$v) {_vm.$set(_vm.dataParent, "nip", $$v)},expression:"dataParent.nip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama"},model:{value:(_vm.dataParent.name),callback:function ($$v) {_vm.$set(_vm.dataParent, "name", $$v)},expression:"dataParent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Panggilan","label-for":"nickname"}},[_c('validation-provider',{attrs:{"name":"nickname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nickname","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama Penggilam"},model:{value:(_vm.dataParent.nickname),callback:function ($$v) {_vm.$set(_vm.dataParent, "nickname", $$v)},expression:"dataParent.nickname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gelar Awal","label-for":"first_title"}},[_c('validation-provider',{attrs:{"name":"first_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Gelar Awal"},model:{value:(_vm.dataParent.first_title),callback:function ($$v) {_vm.$set(_vm.dataParent, "first_title", $$v)},expression:"dataParent.first_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gelar Akhir","label-for":"last_title"}},[_c('validation-provider',{attrs:{"name":"last_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_title","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Gelar Akhir"},model:{value:(_vm.dataParent.last_title),callback:function ($$v) {_vm.$set(_vm.dataParent, "last_title", $$v)},expression:"dataParent.last_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"gender","reduce":function (status) { return status.value; },"placeholder":"Pilih Jenis Kelamin","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataGender,"label":"text"},model:{value:(_vm.dataParent.gender),callback:function ($$v) {_vm.$set(_vm.dataParent, "gender", $$v)},expression:"dataParent.gender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"birth_place"}},[_c('validation-provider',{attrs:{"name":"birth_place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_place","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Tempat Lahir"},model:{value:(_vm.dataParent.birth_place),callback:function ($$v) {_vm.$set(_vm.dataParent, "birth_place", $$v)},expression:"dataParent.birth_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"birth_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"21-05-1992"},model:{value:(_vm.dataParent.birth_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "birth_date", $$v)},expression:"dataParent.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Pendidikan Terakhir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pendidikan Orang Tua","label-for":"education_level","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"education_level","reduce":function (status) { return status.value; },"placeholder":"Pilih Pendidikan Terakhir","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataParentEducation,"label":"text"},model:{value:(_vm.dataParent.education_level),callback:function ($$v) {_vm.$set(_vm.dataParent, "education_level", $$v)},expression:"dataParent.education_level"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Institusi Pendidikan","label-for":"educational_institution"}},[_c('validation-provider',{attrs:{"name":"educational_institution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"educational_institution","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Institusi Pendidikan"},model:{value:(_vm.dataParent.educational_institution),callback:function ($$v) {_vm.$set(_vm.dataParent, "educational_institution", $$v)},expression:"dataParent.educational_institution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"KTP","label-for":"ktp_number"}},[_c('validation-provider',{attrs:{"name":"ktp_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ktp_number","type":"text","state":errors.length > 0 ? false : null,"placeholder":"KTP"},model:{value:(_vm.dataParent.ktp_number),callback:function ($$v) {_vm.$set(_vm.dataParent, "ktp_number", $$v)},expression:"dataParent.ktp_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Agama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Agama","label-for":"religion","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"religion","reduce":function (religion) { return religion.value; },"placeholder":"Pilih Agama","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataReligion,"label":"text"},model:{value:(_vm.dataParent.religion),callback:function ($$v) {_vm.$set(_vm.dataParent, "religion", $$v)},expression:"dataParent.religion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Suku","label-for":"ethnic"}},[_c('validation-provider',{attrs:{"name":"ethnic","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ethnic","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Suku"},model:{value:(_vm.dataParent.ethnic),callback:function ($$v) {_vm.$set(_vm.dataParent, "ethnic", $$v)},expression:"dataParent.ethnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kebangsaan","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nationality","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Kebangsaan"},model:{value:(_vm.dataParent.nationality),callback:function ($$v) {_vm.$set(_vm.dataParent, "nationality", $$v)},expression:"dataParent.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Status Menikah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status Menikah","label-for":"marital_status","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"marital_status","reduce":function (marital_status) { return marital_status.value; },"placeholder":"Pilih Status Menikah","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataMaritalStatus,"label":"text"},model:{value:(_vm.dataParent.marital_status),callback:function ($$v) {_vm.$set(_vm.dataParent, "marital_status", $$v)},expression:"dataParent.marital_status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Aktif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aktif","label-for":"is_active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"is_active","reduce":function (is_active) { return is_active.value; },"placeholder":"Pilih Aktif / Tidak Aktif","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataIsActive,"label":"text"},model:{value:(_vm.dataParent.is_active),callback:function ($$v) {_vm.$set(_vm.dataParent, "is_active", $$v)},expression:"dataParent.is_active"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tipe Pegawai","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"type","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Tipe Pegawai"},model:{value:(_vm.dataParent.type),callback:function ($$v) {_vm.$set(_vm.dataParent, "type", $$v)},expression:"dataParent.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"province","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"province","placeholder":"Pilih Provinsi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataProvinces,"label":"name"},model:{value:(_vm.locations.province),callback:function ($$v) {_vm.$set(_vm.locations, "province", $$v)},expression:"locations.province"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kabupaten/Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kabupaten/Kota","label-for":"city","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"city","placeholder":"Pilih Kabupaten/Kota","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterCities,"label":"name"},model:{value:(_vm.locations.city),callback:function ($$v) {_vm.$set(_vm.locations, "city", $$v)},expression:"locations.city"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kecamatan","label-for":"distric","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"distric","placeholder":"Pilih Kecamatan","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterDistrics,"label":"name"},model:{value:(_vm.locations.distric),callback:function ($$v) {_vm.$set(_vm.locations, "distric", $$v)},expression:"locations.distric"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Desa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Desa","label-for":"subdistrict","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"subdistrict","placeholder":"Pilih Desa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterSubDistrics,"label":"name"},model:{value:(_vm.locations.subdistrict),callback:function ($$v) {_vm.$set(_vm.locations, "subdistrict", $$v)},expression:"locations.subdistrict"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"RW","label-for":"rw"}},[_c('validation-provider',{attrs:{"name":"rw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rw","state":errors.length > 0 ? false : null,"placeholder":"11"},model:{value:(_vm.dataParent.rw),callback:function ($$v) {_vm.$set(_vm.dataParent, "rw", $$v)},expression:"dataParent.rw"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"RT","label-for":"rt"}},[_c('validation-provider',{attrs:{"name":"rt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rt","state":errors.length > 0 ? false : null,"placeholder":"21"},model:{value:(_vm.dataParent.rt),callback:function ($$v) {_vm.$set(_vm.dataParent, "rt", $$v)},expression:"dataParent.rt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Alamat","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Jl. Kaliurang Km. 7 No.B5"},model:{value:(_vm.dataParent.address),callback:function ($$v) {_vm.$set(_vm.dataParent, "address", $$v)},expression:"dataParent.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No. Handphone 1","label-for":"phone_1"}},[_c('validation-provider',{attrs:{"name":"No. Handphone 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_1","state":errors.length > 0 ? false : null,"placeholder":"08xxxxxxxxx"},model:{value:(_vm.dataParent.phone_1),callback:function ($$v) {_vm.$set(_vm.dataParent, "phone_1", $$v)},expression:"dataParent.phone_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No. Handphone 2","label-for":"phone_2"}},[_c('validation-provider',{attrs:{"name":"No. Handphone 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_2","state":errors.length > 0 ? false : null,"placeholder":"08xxxxxxxxx"},model:{value:(_vm.dataParent.phone_2),callback:function ($$v) {_vm.$set(_vm.dataParent, "phone_2", $$v)},expression:"dataParent.phone_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"No. Whatsapp","label-for":"whatsapp"}},[_c('validation-provider',{attrs:{"name":"No. Whatsapp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"whatsapp","state":errors.length > 0 ? false : null,"placeholder":"08xxxxxxxxx"},model:{value:(_vm.dataParent.whatsapp),callback:function ($$v) {_vm.$set(_vm.dataParent, "whatsapp", $$v)},expression:"dataParent.whatsapp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }